import React, { useState } from 'react'

import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Container,
  Box,
  Tabs,
  Tab,
} from '@mui/material'
import logoImg from '../imgs/logos/in2verse_logo.png'
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded'
import { useTranslation } from 'react-i18next'
// import Link from '@mui/material/Link'
// import MenuIcon from '@mui/icons-material/Menu'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

const Header = () => {
  // const [anchorElNav, setAnchorElNav] = useState(null)

  // const handleOpenNavMenu = event => {
  //   setAnchorElNav(event.currentTarget)
  // }

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null)
  // }

  const { i18n } = useTranslation()

  const [korEng, setkorEng] = useState(true)

  const handleChangeLanguage = () => {
    setkorEng(!korEng)
    korEng ? i18n.changeLanguage('en') : i18n.changeLanguage('ko')
  }

  return (
    <AppBar
      color="default"
      elevation={0}
      sx={{
        borderBottom: theme => `1px solid ${theme.palette.divider}`,
      }}
      position="fixed"
    >
      <Container>
        <Toolbar sx={{ flexWrap: 'wrap' }} disableGutters>
          <Box
            component="a"
            href="/"
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            <img
              style={{
                position: 'relative',
                right: '0%',
                left: '0%',
                maxWidth: '100%',
                overflow: 'hidden',
                objectFit: 'cover',
                width: '130px',
                height: '100%',
                margin: '10px 0 0 0',
                flexGrow: 2,
              }}
              alt="in2verse Logo"
              src={logoImg}
            />
          </Box>
          <Tabs sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {/* <Tab
              label="Features"
              variant="button"
              color="text.primary"
              href="/test"
              sx={{ my: 1, mx: 1.5 }}
            ></Tab>
            <Tab
              label="Purchase"
              variant="button"
              color="text.primary"
              href="/pricetable"
              sx={{ my: 1, mx: 1.5 }}
            ></Tab>
            <Tab
              label="Support"
              variant="button"
              color="text.primary"
              href="/todos"
              sx={{ my: 1, mx: 1.5 }}
            ></Tab> */}
          </Tabs>

          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}> */}
          {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton> */}
          {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  variant="button"
                  color="text.primary"
                  href="/test"
                  sx={{ my: 1, mx: 1.5 }}
                >
                  Features
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  variant="button"
                  color="text.primary"
                  href="/pricetable"
                  sx={{ my: 1, mx: 1.5 }}
                >
                  Purchase
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  variant="button"
                  color="text.primary"
                  href="/todos"
                  sx={{ my: 1, mx: 1.5 }}
                >
                  Support
                </Link>
              </MenuItem>
            </Menu> */}
          {/* </Box> */}
          <Box
            component="a"
            href="/"
            sx={{ display: { flexGrow: 1, xs: 'flex', md: 'none' } }}
          >
            <img
              style={{
                position: 'relative',
                right: '0%',
                left: '0%',
                maxWidth: '100%',
                overflow: 'hidden',
                objectFit: 'cover',
                width: '130px',
                height: '100%',
                margin: '10px 0 ',
              }}
              alt="in2verse Logo"
              src={logoImg}
            />
          </Box>
          <IconButton
            color="inherit"
            sx={{
              color: 'grey',
              rounded: '50px',
            }}
            onClick={handleChangeLanguage}
          >
            <TranslateRoundedIcon sx={{ color: '#9e9e9e' }} />
          </IconButton>
          <Button href="/login" variant="contained" sx={{ my: 1, mx: 1.5 }}>
            Login
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
