import React from 'react'
import { Grid, Typography, Link, Container, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="/">
        IN2VERSE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const Footer = () => {
  const { t } = useTranslation()
  const footers = {
    title: t('footerTitle'),
    body: [
      t('footerLocation'),
      '(+82) 02-704-8758, 042-335-8785',
      'contact@in2wise.com',
    ],
  }

  return (
    <>
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: theme => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
          position: 'static',
        }}
      >
        <Grid container spacing={1} justifyContent="space-evenly">
          <Grid item xs={12} sm={10}>
            <Typography variant="h5" color="text.primary" gutterBottom>
              {footers.title}
            </Typography>
            <Box sx={{ display: 'grid' }}>
              {footers.body.map(item => (
                <Typography variant="body2" key={item}>
                  {item}
                </Typography>
              ))}
            </Box>
          </Grid>
          <Grid item sm={2}>
            {/* <Link>Contact US</Link> */}
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </>
  )
}

export default Footer
