import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import mlopsEduImgKor from './imgs/mlops_edu.png'
const resources = {
  en: {
    translation: {
      welcome: 'Good morning.',
      pricingInfo: 'Mlops Detail',
      mlopsPrice: 'contact us for a QUOTE',
      footerTitle: 'in2verse',
      footerLocation:
        '(61011)  A-405, 43-10, Cheomdangwagi-ro 208beon-gil, Buk-gu, Gwangju, Republic of Korea',
      mlopsImage: mlopsEduImgKor,
    },
  },
  ko: {
    translation: {
      welcome: '좋은 아침 입니다.',
      pricingInfo: 'Mlops 세부정보',
      mlopsPrice: '가격 문의',
      footerTitle: '(주) 인투버스',
      footerLocation:
        '(61011) 광주광역시 북구 첨단과기로208번길 43-10 마루힐IBC지식산업센터 405호',
      mlopsImage: mlopsEduImgKor,
    },
  },
}
i18n.use(initReactI18next).init({
  resources,
  lng: 'ko',
})
export default i18n
