import React, { useState } from 'react'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormGroup,
  FormLabel,
} from '@mui/material'

export default function Signup() {
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('paper')

  const handleClickOpen = scrollType => () => {
    setOpen(true)
    setScroll(scrollType)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  // const server_URL = '/api/signup'
  // const handleSubmit = async e => {
  //   e.preventDefault()
  //   const data = new FormData(e.currentTarget)
  //   const email = data.get('email')
  //   const password = data.get('password')

  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   })

  //   await axios.post(server_URL, { email, password })
  // }

  return (
    <>
      <Box
        component="a"
        sx={{ cursor: 'pointer', color: 'primary.dark' }}
        onClick={handleClickOpen('paper')}
      >
        Sign up
      </Box>

      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">Sign up</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <Box
            component="form"
            onSubmit={handleSubmit(async data => {
              console.log(data)

              await axios.post('/api/signups', JSON.stringify(data), {
                headers: { 'Content-type': 'application/json' },
              })
            })}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormGroup
              sx={{
                padding: 2,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'primary.main',
              }}
            >
              <FormLabel component="legend">Name</FormLabel>
              <Controller
                control={control}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[가-힣|a-z]+$/,
                    message: 'Invalid name',
                  },
                }}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    {...field}
                    onChange={onChange}
                    margin="dense"
                    label="Name"
                    type="text"
                    value={value || ''}
                    fullWidth
                    required
                    autoFocus
                    error={Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
                autoFocus
                id="name"
                name="name"
              />
              <FormLabel component="legend">E-mail</FormLabel>
              <Controller
                control={control}
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^\S+@\S+\.\S+$/i,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    margin="dense"
                    onChange={onChange}
                    onBlur={onBlur}
                    label="Email Address"
                    type="email"
                    fullWidth
                    required
                    error={Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                  />
                )}
                id="email"
                name="email"
              />
              <FormLabel component="legend">phone</FormLabel>
              <Controller
                control={control}
                rules={{
                  required: true,
                  // pattern: {
                  //   value: /^[0-9]+-[0-9]+-[0-9]+$/,
                  //   message: 'Invalid email address',
                  // },
                }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    onChange={onChange}
                    onBlur={onBlur}
                    margin="dense"
                    label="phone"
                    type="phone"
                    fullWidth
                    required
                    error={Boolean(errors.phoneNumber)}
                    helperText={
                      errors.phoneNumber && errors.phoneNumber.message
                    }
                  />
                )}
                id="phoneNumber"
                name="phoneNumber"
              />
              <FormLabel component="legend">Company</FormLabel>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur } }) => (
                  <TextField
                    onChange={onChange}
                    onBlur={onBlur}
                    margin="dense"
                    label="company"
                    type="text"
                    fullWidth
                    required
                    error={Boolean(errors.company)}
                    helperText={errors.company && errors.company.message}
                  />
                )}
                id="company"
                name="company"
              />

              <DialogActions>
                <Button color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit">Submit</Button>
              </DialogActions>
            </FormGroup>

            {/* <DialogActions>
          <Button color='error' onClick={handleClose} >Cancel</Button>
          <Button type="submit" >Submit</Button>
        </DialogActions> */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
