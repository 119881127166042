import { Typography, Container, ImageList, ImageListItem } from '@mui/material'
import React from 'react'
import kmuLogo from '../../imgs/logos/KMU_LOGO.jpg'

const Partners = () => {
  return (
    <Container>
      <Typography
        component="h1"
        variant="h3"
        align="center"
        color="text.primary"
        gutterBottom
        sx={{ mb: 5 }}
        fontFamily="SB_AGGRO_M"
      >
        Partners
      </Typography>
      <ImageList
        sx={{ width: '100%', height: '160px' }}
        cols={2}
        rowHeight={150}
      >
        {partnerImgs.map(item => (
          <ImageListItem key={item.img} sx={{ alignItems: 'center' }}>
            <a href={item.url}>
              <img
                src={`${item.img}?w=150&h=80&fit=crop&auto=format`}
                srcSet={`${item.img}?w=150&h804&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                width="150px"
                // height="50px"
              />
            </a>
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  )
}

export default Partners

const partnerImgs = [
  {
    img: 'https://in2wise.com/wp-content/uploads/2022/11/cropped-%EC%9D%B8%ED%88%AC%EC%99%80%EC%9D%B4%EC%A6%88-CI_%EB%8C%80%EC%A7%80-1-2048x588.png',
    title: 'in2wise',
    url: 'http://www.in2wise.com/',
  },

  {
    img: kmuLogo,
    title: 'KMU',
    url: 'https://www.kookmin.ac.kr/',
  },
]
