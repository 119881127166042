import React from 'react'
import { Container, Box, Typography } from '@mui/material'

const Locations = () => {
  return (
    <Container>
      <Typography
        component="h1"
        variant="h3"
        // align="center"
        color="text.primary"
        gutterBottom
        sx={{ mb: 5 }}
        fontFamily="SB_AGGRO_M"
      >
        Location
      </Typography>
      <Box
        component="iframe"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13037.13612604365!2d126.8503241!3d35.2242989!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6b4af22b4f5b4cd!2z66eI66Oo7Z6QSUJD7KeA7Iud7IKw7JeF7IS87YSw!5e0!3m2!1sko!2skr!4v1675228494925!5m2!1sko!2skr"
        frameBorder="0"
        aria-hidden="false"
        sx={{
          width: '100%',
          height: '60vh',
          alignItems: 'center',
          // ml: '15%',
        }}
      />
    </Container>
  )
}

export default Locations
