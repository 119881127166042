import { Box, Slide, Paper, Button } from '@mui/material'
import React from 'react'
import mainImg from '../../imgs/in2wiseMain.jpg'
import MlopsPricing from '../MlopsPricing'
import Partners from './Partners'
import Locations from './Locations'
import useTitle from '../../component/useTitle'

const Main = () => {
  const titleUpdater = useTitle('Loading...')

  setTimeout(() => titleUpdater('인투버스 - Home'), 500)
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          textAlign: 'center',
          fontSize: '32px',
          color: '#fff',
          fontFamily: 'Inter',
          width: '100%',
          height: '100vh',
        }}
      >
        <img
          style={{
            position: 'absolute',
            right: '0%',
            left: '0%',
            maxWidth: '100%',
            overflow: 'hidden',
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            opacity: '50%',
          }}
          alt=""
          src={mainImg}
        />
        <Box
          sx={{
            position: 'absolute',
            background: 'rgba(0,0,0,0.7)',
            width: '100%',
            height: '100%',
          }}
        >
          <Slide direction="up" in timeout={500} mountOnEnter unmountOnExit>
            <Paper
              sx={{ my: '15%', background: 'none', color: 'white' }}
              elevation={0}
            >
              <Box component="h1">IN2VERSE</Box>
              <hr width="40px" />
              <Box component="h5">CHANGE YOURS INTO WISE THINGS!</Box>
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  borderRadius: 10,
                  py: 2,
                  px: 6,
                  border: '2px solid white',
                }}
                href="/"
              >
                ABOUT US
              </Button>
            </Paper>
          </Slide>
        </Box>
      </Box>
      <MlopsPricing></MlopsPricing>
      <br></br>
      <Partners></Partners>
      <br />
      <Locations></Locations>
    </>
  )
}

export default Main
