import React from 'react'
import axios from 'axios'
import Signup from './Signup'
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Grid,
  Link,
  Typography,
  Avatar,
  Box,
} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import useTitle from '../component/useTitle'

const Login = () => {
  const titleUpdater = useTitle('Loading...')

  setTimeout(() => titleUpdater('인투버스 - Login'), 500)
  const server_URL = '/api/logins'
  const handleSubmit = async e => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)
    const email = data.get('email')
    const password = data.get('password')

    console.log({
      email: data.get('email'),
      password: data.get('password'),
    })

    const response = await axios.post(server_URL, { email, password })
    if (response.status === 200) {
      // The user successfully logged in
    } else {
      // Login was unsuccessful
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          my: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '50vh',
          // display: 'table-cell',
          justifyContent: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: '#006666' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
          <TextField
            name="email"
            label="Email Address"
            required
            fullWidth
            autoComplete="email"
            autoFocus
            margin="normal"
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            required
            fullWidth
            autoComplete="current-password"
            margin="normal"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 3 }}
          >
            {' '}
            Log-in{' '}
          </Button>
        </Box>
        <Grid container>
          <Grid item xs>
            <Link>Forgot password</Link>
          </Grid>
          <Grid item>
            <Signup></Signup>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Login
