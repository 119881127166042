import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
  Box,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  GlobalStyles,
  Container,
} from '@mui/material'

import MlopsOrder from './MlopsOrder'
import { useTranslation } from 'react-i18next'

function MlopsPricing() {
  const [products, setProducts] = useState('')
  const fetchData = async () => {
    const response = await axios.get('https://in2verse.co.kr/api/products')
    setProducts(response.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
      />
      <CssBaseline />

      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          maxwidth: '70%',
          width: '70%',
          mx: '15%',
          position: 'relative',
        }}
      >
        <img src={t('mlopsImage')} alt="mlops images" width="100%" />
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          width: '100%',
          position: 'relative',
        }}
      >
        <img src={t('mlopsImage')} alt="" width="100%" />
      </Box>
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 1, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
          fontFamily="SB_AGGRO_M"
        >
          Pricing
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
          fontFamily="Noto Sans KR"
        >
          {t('pricingInfo')}
        </Typography>
      </Container>

      <Container maxWidth="md" component="main" sx={{ mb: 6 }}>
        <Grid container spacing={5} alignItems="flex-end">
          {products.content?.map(product => (
            <Grid
              item
              key={product.productId}
              xs={12}
              sm={product.type === 'Enterprise' ? 12 : 6}
              md={6}
            >
              <Card>
                <CardHeader
                  title={product.type}
                  subheader={product.type}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: theme =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />

                <CardContent>
                  <Box
                    className="notosanskr"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    {product.price ? (
                      <>
                        <Typography
                          component="h2"
                          variant="h3"
                          color="text.primary"
                        >
                          ${product.price}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          /mo
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="h6" color="text.secondary">
                          {t('mlopsPrice')}
                        </Typography>
                      </>
                    )}
                  </Box>
                  <ul>
                    {/* {product.map(line => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))} */}
                  </ul>
                </CardContent>
                <CardActions>
                  <MlopsOrder select={product.type}> </MlopsOrder>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default MlopsPricing
